import React from 'react';
import { Typography, Paper, Grid, Avatar, Button, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';

const ViewOrganizationProfile = ({ organization, onEdit }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              src={organization.logo}
              alt={organization.name}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              {organization.name}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Edit />}
              onClick={onEdit}
              sx={{ mt: 2 }}
            >
              Edit Organization
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Organization Details
          </Typography>
          <Typography>
            <strong>Business Registration (Old):</strong> {organization.businessRegOld}
          </Typography>
          <Typography>
            <strong>Business Registration (New):</strong> {organization.businessRegNew}
          </Typography>
          <Typography>
            <strong>Address:</strong> {organization.address}
          </Typography>
          <Typography>
            <strong>Website:</strong> {organization.website}
          </Typography>
          <Typography>
            <strong>Status:</strong> {organization.status}
          </Typography>
          <Typography>
            <strong>TIN:</strong> {organization.TIN}
          </Typography>
          
          <Typography variant="h6" sx={{ mt: 3 }} gutterBottom>
            Contact Person
          </Typography>
          <Typography>
            <strong>Name:</strong> {organization.contactPerson.name}
          </Typography>
          <Typography>
            <strong>Email:</strong> {organization.contactPerson.email}
          </Typography>
          <Typography>
            <strong>Phone:</strong> {organization.contactPerson.phone}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewOrganizationProfile;