import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, db, deleteDoc } from '../../../api/firebase';
import ViewOrganization from './view';
import EditOrganization from './edit';
import { Box, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import DeleteDialog from '../../../components/DeleteDialog';
import { useSnackbar } from 'notistack';

const OrganizationManager = () => {
  const [organization, setOrganization] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const [isDeleting, setIsDeleting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        console.log('Fetching organization data...');
        console.log('Organization ID:', organizationId);
        const docRef = doc(db, 'organizations', organizationId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setOrganization(docSnap.data());
        } else {
          setError('Organization not found');
        }
      } catch (err) {
        setError('Error fetching organization data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [organizationId]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleDelete = () => {
    setIsDeleting(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteDoc(doc(db, 'organizations', organizationId));
      setIsDeleting(false);
        enqueueSnackbar('Organization deleted successfully', { variant: 'success' });
      navigate('/organizations'); // Redirect to organizations list
    } catch (err) {
      setError('Error deleting organization');
      enqueueSnackbar('Error deleting organization: ' + err.message, { variant: 'error' });
      console.error(err);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleting(false);
  };

  const handleSave = async (updatedData) => {
    try {
      const docRef = doc(db, 'organizations', organizationId);
      await updateDoc(docRef, updatedData);
      setOrganization(updatedData);
      setIsEditing(false);
    } catch (err) {
      setError('Error updating organization data');
      console.error(err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
        <IconButton onClick={() => navigate('/admin/organization')} style={{ marginBottom: '20px' }}>
            <ArrowBack />
            <h4>Back</h4>
        </IconButton>
      {isEditing ? (
        <EditOrganization
          organization={organization}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <ViewOrganization
          organization={organization}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      )}
        <DeleteDialog
            open={isDeleting}
            itemName={organization?.name}
            onClose={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
            onCancel={handleDeleteCancel}
        />
    </div>
  );
};

export default OrganizationManager;