import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Paper, 
  Typography, 
  Button,
    Grid,
  Box,
  CircularProgress,
  Alert,
  IconButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchSubmissionDetail } from '../../api/api-backend/myinvois_api';
import { formatDate } from '../../utils/helper';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const DebitNoteSubmissionDetail = () => {
  const { submissionUid, organizationId } = useParams();
  const navigate = useNavigate();
  const [submissionDetail, setSubmissionDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getSubmissionDetail = async () => {
      try {
        setLoading(true);
        const data = await fetchSubmissionDetail(organizationId, submissionUid);
        setSubmissionDetail(data);
      } catch (err) {
        setError('Failed to fetch submission detail. Using dummy data.');
        // Fallback to dummy data
        setSubmissionDetail({
          submissionUid: submissionUid,
          documentCount: 2,
          dateTimeReceived: "2024-08-01T12:35:22.788Z",
          overallStatus: "Completed",
          documentSummary: [
            {
              uuid: "doc1",
              submissionUid: submissionUid,
              longId: "LONG123",
              internalId: "INT456",
              typeName: "Invoice",
              typeVersionName: "1.0",
              issuerTin: "123456789",
              issuerName: "ABC Company",
              receiverId: "RCV789",
              receiverName: "XYZ Corp",
              dateTimeIssued: "2024-08-01T10:00:00.000Z",
              dateTimeReceived: "2024-08-01T12:35:22.788Z",
              dateTimeValidated: "2024-08-01T12:40:00.000Z",
              totalExcludingTax: 1000,
              totalDiscount: 50,
              totalNetAmount: 950,
              totalPayableAmount: 1045,
              status: "Validated",
              cancelDateTime: null,
              rejectRequestDateTime: null,
              documentStatusReason: null,
              createdByUserId: "USER123"
            }
          ]
        });
      } finally {
        setLoading(false);
      }
    };

    getSubmissionDetail();
  }, [submissionUid]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, m: 2 }}>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate(-1)}
        sx={{ mb: 2 }}
      >
        Back to Submissions
      </Button>
      
      {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

      <Typography variant="h4" gutterBottom>
        Debit Note Submission Detail
      </Typography>
      
      {submissionDetail && (
        <>
          <Box sx={{ mb: 3 }}>
            <Typography variant="h6">General Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography><strong>Submission UID:</strong> {submissionDetail.submissionUid}</Typography>
                <Typography><strong>Document Count:</strong> {submissionDetail.documentCount}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography><strong>Received:</strong> {formatDate(submissionDetail.dateTimeReceived)}</Typography>
                <Typography><strong>Overall Status:</strong> {submissionDetail.overallStatus}</Typography>
              </Grid>
            </Grid>
          </Box>
          
          <Typography variant="h6" gutterBottom>Document Summary</Typography>
          {submissionDetail.documentSummary.map((doc, index) => (
            <Box key={doc.uuid} sx={{ mb: 3, p: 2, border: '1px solid #e0e0e0', borderRadius: '4px' }}>
              <Typography variant="h6" gutterBottom>Document {index + 1}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button color="secondary" onClick={()=>navigate(`/debitnote/view/document/${doc.uuid}`)}>View Document</Button>
                  <Typography><strong>UUID:</strong> {doc.uuid} <IconButton color="inherit" size="small" height="" onClick={() => navigator.clipboard.writeText(doc.uuid)}><FileCopyIcon /></IconButton></Typography>
                  <Typography><strong>Submission UID:</strong> {doc.submissionUid}</Typography>
                  <Typography><strong>Long ID:</strong> {doc.longId}</Typography>
                  <Typography><strong>Internal ID:</strong> {doc.internalId}</Typography>
                  <Typography><strong>Type Name:</strong> {doc.typeName}</Typography>
                  <Typography><strong>Type Version:</strong> {doc.typeVersionName}</Typography>
                  <Typography><strong>Issuer TIN:</strong> {doc.issuerTin}</Typography>
                  <Typography><strong>Issuer Name:</strong> {doc.issuerName}</Typography>
                  <Typography><strong>Receiver ID:</strong> {doc.receiverId}</Typography>
                  <Typography><strong>Receiver Name:</strong> {doc.receiverName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography><strong>Issued:</strong> {formatDate(doc.dateTimeIssued)}</Typography>
                  <Typography><strong>Received:</strong> {formatDate(doc.dateTimeReceived)}</Typography>
                  <Typography><strong>Validated:</strong> {formatDate(doc.dateTimeValidated)}</Typography>
                  <Typography><strong>Total Excluding Tax:</strong> {doc.totalExcludingTax}</Typography>
                  <Typography><strong>Total Discount:</strong> {doc.totalDiscount}</Typography>
                  <Typography><strong>Total Net Amount:</strong> {doc.totalNetAmount}</Typography>
                  <Typography><strong>Total Payable Amount:</strong> {doc.totalPayableAmount}</Typography>
                  <Typography><strong>Status:</strong> {doc.status}</Typography>
                  <Typography><strong>Cancel Date:</strong> {formatDate(doc.cancelDateTime)}</Typography>
                  <Typography><strong>Reject Request Date:</strong> {formatDate(doc.rejectRequestDateTime)}</Typography>
                  <Typography><strong>Status Reason:</strong> {doc.documentStatusReason || 'N/A'}</Typography>
                  <Typography><strong>Created By:</strong> {doc.createdByUserId}</Typography>
                </Grid>
              </Grid>
            </Box>
          ))}
        </>
      )}
    </Paper>
  );
};

export default DebitNoteSubmissionDetail;