import React, { useState, useEffect, useContext } from 'react';
import { Container, Grid, Paper, Typography, Select, MenuItem, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box, Chip, Divider, List, ListItem, FormControl, InputLabel, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { collection, db, getDocs, query } from '../../api/firebase';
import moment from 'moment';

import { fetchDashboardData } from '../../api/api-backend/myinvois_api';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const ScrollableList = styled(List)({
  maxHeight: 800,
  overflow: 'auto',
});

const StyledListItem = styled(ListItem)({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#f5f5f5',
  },
});

const documentTypeColors = {
  'Invoice': 'primary',
  'Credit Note': 'secondary',
  'Debit Note': 'error',
  'Refund Note': 'warning'
};

const FixedWidthTypography = styled(Typography)({
  width: '250px',
  textAlign: 'center',
});

const Dashboard = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [recentSubmissions, setRecentSubmissions] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const isAdmin = user != null  && user.role === 'admin';
  const currentUser = user;
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchOrganizationsAndSetDefault = async () => {
      if (isAdmin) {
        const orgsCollection = collection(db, 'organizations');
        const orgsSnapshot = await getDocs(orgsCollection);
        const orgsList = orgsSnapshot.docs.map(doc => ({
          id: doc.id,
          name: doc.data().name
        }));
        setOrganizations(orgsList);
        if (orgsList.length > 0) {
          setSelectedOrg(orgsList[0].id);
        }
      } else if (currentUser.orgId) {
        setSelectedOrg(currentUser.orgId);
      }
    };

    fetchOrganizationsAndSetDefault();
  }, [isAdmin, currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedOrg) return;

      setLoading(true);
      setError(null);

      try {
        const data = await fetchDashboardData(selectedMonth + 1, selectedYear, selectedOrg);
        console.log('Fetched dashboard data:', data);
        setDashboardData(data);

        const submissionsQuery = query(
          collection(db, 'organizations', selectedOrg, 'submissions')
        );
        const submissionsSnapshot = await getDocs(submissionsQuery);
        const submissionsList = submissionsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        const sortedSubmissions = submissionsList.sort((a, b) => b.createdDate.seconds - a.createdDate.seconds);
        console.log('Fetched submissions:', sortedSubmissions);
        setSubmissions(sortedSubmissions);
        setRecentSubmissions(sortedSubmissions);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError('Failed to load dashboard data. Please try again.');
        setDashboardData(null);
        setRecentSubmissions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedMonth, selectedYear, selectedOrg]);

  const getMonthOptions = () => {
    const currentMonth = new Date().getMonth();
    const months = [];
    for (let i = 0; i <= 5; i++) {
      const month = new Date(new Date().setMonth(currentMonth - i));
      months.push({
        value: month.getMonth(),
        label: month.toLocaleString('default', { month: 'long', year: 'numeric' }),
      });
    }
    return months;
  };

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

  const renderTile = (title, dataCount, dataAcceptedCount, dataRejectedCount) => (
    <Grid item xs={12} sm={6} md={3}>
      <StyledPaper elevation={3} sx={{ 
        bgcolor: 'primary.light', 
        color: 'primary.contrastText',
        '&:hover': { 
          bgcolor: 'primary.main',
          transition: 'background-color 0.3s'
        }
      }}>
        <Typography variant="h6" gutterBottom>{title} Submissions</Typography>
        <Typography variant="h4" gutterBottom>{dataCount || 0}</Typography>
        <div>
          <Typography>Accepted: {dataAcceptedCount || 0}</Typography>
          <Typography>Rejected: {dataRejectedCount || 0}</Typography>
        </div>
      </StyledPaper>
    </Grid>
  );

  const renderDocumentList = (documents) => (
    <ul style={{ margin: 0 }}>
      {documents.map((doc, index) => (
        <li key={index}>
          <Tooltip title={`UUID: ${doc.uuid}`}>
            <span>{doc.invoiceCodeNumber}</span>
          </Tooltip>
        </li>
      ))}
    </ul>
  );

  const formatDate = (seconds) => {
    return moment.unix(seconds).format('DD-MM-YYYY HH:mm:ss');
  };

  const renderSubmissionsList = () => (
    <ScrollableList>
      {recentSubmissions.map((submission) => ( 
        <StyledListItem key={submission.submissionUid} divider onClick={() => handleRowClick(submission.submissionUid)}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={1}>
              <Chip
                label={submission.documentType}
                color={documentTypeColors[submission.documentType] || 'default'}
                size="small"
              />
            </Grid>
            <Grid item xs={3}>
              <FixedWidthTypography variant="body2">ID: {submission.submissionUid}</FixedWidthTypography>
            </Grid>
            <Grid item xs={2}>
              <FixedWidthTypography variant="body2">
                Accepted: {submission.acceptedDocuments.length}
                {renderDocumentList(submission.acceptedDocuments)}
              </FixedWidthTypography>
            </Grid>
            <Grid item xs={2}>
              <FixedWidthTypography variant="body2">
                Rejected: {submission.rejectedDocuments.length}
                {renderDocumentList(submission.rejectedDocuments)}
              </FixedWidthTypography>
            </Grid>
            <Grid item xs={2}>
              <FixedWidthTypography variant="body2">Total: {submission.totalDocumentCount}</FixedWidthTypography>
            </Grid>
            <Grid item xs={1}>
              <FixedWidthTypography variant="body2">
              {formatDate(submission.createdDate.seconds)}
              </FixedWidthTypography>
            </Grid>
          </Grid>
        </StyledListItem>
      ))}
    </ScrollableList>
  );

  if (loading) {
    return (
      <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  const handleRowClick = (submissionUid) => {
    //navigate(`/submission/${submissionUid}`);
  };

  return (
    <Container>
      {isAdmin && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="org-select-label">Organization</InputLabel>
          <Select
            labelId="org-select-label"
            id="org-select"
            value={selectedOrg}
            label="Organization"
            onChange={handleOrgChange}
          >
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            fullWidth
          >
            {getMonthOptions().map((month) => (
              <MenuItem key={month.value} value={month.value}>
                {month.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {dashboardData && (
          <>
            {renderTile('Invoices', dashboardData.invoiceCount, dashboardData.invoiceAcceptedCount, dashboardData.invoiceRejectedCount)}
            {renderTile('Credit Notes', dashboardData.creditNoteCount, dashboardData.creditNoteAcceptedCount, dashboardData.creditNoteRejectedCount)}
            {renderTile('Debit Notes', dashboardData.debitNoteCount, dashboardData.debitNoteAcceptedCount, dashboardData.debitNoteRejectedCount)}
            {renderTile('Refund Notes', dashboardData.refundNoteCount, dashboardData.refundNoteAcceptedCount, dashboardData.refundNoteRejectedCount)}
          </>
        )}
      </Grid>
      <Box>
        <Divider sx={{ my: 4 }} />
        <Typography variant="h6" gutterBottom>Recent Submissions</Typography>
        {loading && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100px"> 
            <CircularProgress />
          </Box>
        )}
        {!loading && recentSubmissions.length === 0 && (
          <Typography variant="body2">No recent submissions found.</Typography> 
        )}
        {!loading && recentSubmissions.length > 0 && renderSubmissionsList()}
      </Box>
    </Container>
  );
};

export default Dashboard;