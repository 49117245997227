import React, { useState } from 'react';
import { TextField, Button, Paper, Grid, Typography, IconButton, CircularProgress, Box,
   InputAdornment } from '@mui/material';
import { CloudUpload, Visibility, VisibilityOff } from '@mui/icons-material';
import { ref, uploadBytes, getDownloadURL, storage, auth, updatePassword } from '../../api/firebase';
import { useSnackbar } from 'notistack';

const EditProfileForm = ({ user, onSave, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [profilePicPreview, setProfilePicPreview] = useState(user.profilePic);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    profilePic: user.profilePic,
    email: user.email,
    position: user.position,
    phoneNo: user.phoneNo,
    password: ''
  });
  const [profilePicFile, setProfilePicFile] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleProfilePicChange = (event) => {
    if (event.target.files[0]) {
      setProfilePicFile(event.target.files[0]);
      setProfilePicPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const uploadProfilePic = async () => {
    if (!profilePicFile) return formData.profilePic;
    const storageRef = ref(storage, `einvoice/users/${profilePicFile.name}`);
    await uploadBytes(storageRef, profilePicFile);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const profilePicUrl = await uploadProfilePic();
      const userData = {
        ...formData,
        profilePic: profilePicUrl,
        updatedDate: new Date()
      };
      
      // Remove password from Firestore data
      delete userData.password;

      await onSave(userData);

      if (formData.password) {
        await updatePassword(auth.currentUser, formData.password);
      }

      enqueueSnackbar('Profile updated successfully!', { variant: 'success' });
    } catch (error) {
      console.error('Error updating profile:', error);
      enqueueSnackbar('Error updating profile: ' + error.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
      <Typography variant="h5" gutterBottom>Edit Profile</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Position"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="profilePic-file"
              type="file"
              onChange={handleProfilePicChange}
            />
            <label htmlFor="profilePic-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
              >
                Change Profile Picture
              </Button>
            </label>
            {profilePicFile && <Typography variant="body2">{profilePicFile.name}</Typography>}
            {profilePicPreview && (
              <Box mt={2} textAlign="center">
                <Typography variant="body2">Preview:</Typography>
                <img src={profilePicPreview} alt="Profile Pic Preview" style={{ maxWidth: '100%', maxHeight: '100px' }} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password (leave blank to keep current)"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading} style={{ marginRight: '10px' }}>
              {isLoading ? <CircularProgress size={24} /> : 'Update Profile'}
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default EditProfileForm;