import React, { useEffect, useState } from 'react';
import { TextField, Button, Paper, Grid, Typography, InputAdornment, IconButton,
    CircularProgress, Box, FormControl, InputLabel, Select, MenuItem
  } from '@mui/material';
import { ArrowBack, CloudUpload, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, db, storage, collection, addDoc,
  createUserWithEmailAndPassword, auth
 } from '../../../api/firebase';
import { useSnackbar } from 'notistack';
import { getAllOrganizations } from '../../../api/firebase-backend';
import { userApi } from '../../../api/api-backend/user_api';

const CreateUserForm = () => {
  const navigate = useNavigate();
  const [orgs, setOrgs] = useState([]);
  console.log(orgs);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [profilePicPreview, setProfilePicPreview] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    profilePic: '',
    email: '',
    phoneNo: '',
    position: '',
    orgName: '',
    orgId: '',
    status: 'Inactive',
    password: ''
  });
  const [profilePicFile, setProfilePicFile] = useState(null);

  useEffect(() => {
    const fetchOrganizations = async () => {
      const orgList = await getAllOrganizations();
      setOrgs(orgList);
      console.log(orgs);
    };
    fetchOrganizations();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'orgId') {
      const selectedOrg = orgs.find(org => org.id === value);
      setFormData(prevData => ({
        ...prevData,
        orgId: value,
        orgName: selectedOrg ? selectedOrg.name : ''
      }));
    } else if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prevData => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleProfilePicChange = (event) => {
    if (event.target.files[0]) {
      setProfilePicFile(event.target.files[0]);
      setProfilePicPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const uploadProfilePic = async () => {
    if (!profilePicFile) return null;
    const storageRef = ref(storage, `einvoice/users/${profilePicFile.name}`);
    await uploadBytes(storageRef, profilePicFile);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    try {
      // const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      // const user = userCredential.user;


      // const profilePicUrl = await uploadProfilePic();
      // const userData = {
      //   ...formData,
      //   orgName: orgs.find(org => org.id === formData.orgId).name,
      //   profilePic: profilePicUrl,
      //   createdDate: new Date(),
      //   updatedDate: new Date(),
      //   uid: user.uid
      // };

      // // Remove password from Firestore data
      // delete userData.password;
      
      // const docRef = await addDoc(collection(db, "users"), userData);
      const profilePicUrl = await uploadProfilePic();
      const userData = {
        ...formData,
        profilePic: profilePicUrl,
      };

      const data = await userApi.createUser(userData);
      console.log("User created with ID: ", data.Uid);
      enqueueSnackbar('User created successfully!', { variant: 'success' });
      navigate('/admin/user');
    } catch (error) {
      console.error('Error creating user:', error);
      enqueueSnackbar('Error creating user: ' + error.message, { variant: 'error' });
    }
    setIsLoading(false); // Stop loading
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
        <IconButton onClick={() => navigate('/admin/user')} style={{ marginBottom: '20px' }}>
            <ArrowBack />
        </IconButton>
      <Typography variant="h5" gutterBottom>Create User</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">User Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Position"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="profilePic-file"
              type="file"
              onChange={handleProfilePicChange}
            />
            <label htmlFor="profilePic-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
              >
                Upload Profile Picture
              </Button>
            </label>
            {profilePicFile && <Typography variant="body2">{profilePicFile.name}</Typography>}
            {profilePicPreview && (
            <Box mt={2} textAlign="center">
              <Typography variant="body2">Preview:</Typography>
              <img src={profilePicPreview} alt="Profile Pic Preview" style={{ maxWidth: '100%', maxHeight: '100px' }} />
            </Box>
          )}
          </Grid>
          <Grid item xs={12}>
          <FormControl fullWidth>
                <InputLabel id="status-label">Organization</InputLabel>
                <Select
                    labelId="org-label"
                    id="orgId"
                    name="orgId"
                    value={formData.orgId}
                    label="Organization"
                    onChange={handleChange}
                    >
                    {orgs.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                        {option.id} - {option.name}
                        </MenuItem>
                    ))}
                    {/* <TextField type="hidden" name="orgName" value={formData.orgName} /> */}
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
            {isLoading ? <CircularProgress size={24} /> : 'Create User'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateUserForm;