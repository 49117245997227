import { storage, ref, getDownloadURL } from "../api/firebase";

// Get download URL of a file from Firebase Storage via Promise not async/await
export const getFileUrl = async (filePath) => {
  try {
    const storageRef = ref(storage, filePath);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error("Error getting file URL:", error);
    return "";
  }
};
