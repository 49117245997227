import React, { useState } from 'react';
import { TextField, Button, Paper, Grid, Typography, InputAdornment, IconButton,
    CircularProgress, Box
  } from '@mui/material';
import { ArrowBack, CloudUpload } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, db, storage, collection, addDoc } from '../../../api/firebase';
import { useSnackbar } from 'notistack';

const CreateOrganizationForm = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [logoPreview, setLogoPreview] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    businessRegOld: '',
    businessRegNew: '',
    logo: '',
    address: '',
    website: '',
    contactPerson: {
      name: '',
      email: '',
      phone: ''
    },
    status: 'Inactive'
  });
  const [logoFile, setLogoFile] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prevData => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleLogoChange = (event) => {
    if (event.target.files[0]) {
      setLogoFile(event.target.files[0]);
      setLogoPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const uploadLogo = async () => {
    if (!logoFile) return null;
    const storageRef = ref(storage, `einvoice/logos/${logoFile.name}`);
    await uploadBytes(storageRef, logoFile);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Start loading
    try {
      const logoUrl = await uploadLogo();
      const organizationData = {
        ...formData,
        logo: logoUrl,
        createdDate: new Date(),
        updatedDate: new Date()
      };
      
      const docRef = await addDoc(collection(db, "organizations"), organizationData);
      console.log("Organization created with ID: ", docRef.id);
      enqueueSnackbar('Organization created successfully!', { variant: 'success' });
      navigate('/admin/organization');
    } catch (error) {
      console.error('Error creating organization:', error);
      enqueueSnackbar('Error creating organization: ' + error.message, { variant: 'error' });
    }
    setIsLoading(false); // Stop loading
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
        <IconButton onClick={() => navigate('/admin/organization')} style={{ marginBottom: '20px' }}>
            <ArrowBack />
        </IconButton>
      <Typography variant="h5" gutterBottom>Create Organization</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">Organization Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Organization Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Business Registration (Old)"
              name="businessRegOld"
              value={formData.businessRegOld}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Business Registration (New)"
              name="businessRegNew"
              value={formData.businessRegNew}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="logo-file"
              type="file"
              onChange={handleLogoChange}
            />
            <label htmlFor="logo-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
              >
                Upload Logo
              </Button>
            </label>
            {logoFile && <Typography variant="body2">{logoFile.name}</Typography>}
            {logoPreview && (
            <Box mt={2} textAlign="center">
              <Typography variant="body2">Preview:</Typography>
              <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '100px' }} />
            </Box>
          )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Website"
              name="website"
              value={formData.website}
              onChange={handleChange}
              InputProps={{
                startAdornment: <InputAdornment position="start">https://</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Contact Person</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="contactPerson.name"
              value={formData.contactPerson.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email Address"
              name="contactPerson.email"
              type="email"
              value={formData.contactPerson.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="contactPerson.phone"
              value={formData.contactPerson.phone}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
            {isLoading ? <CircularProgress size={24} /> : 'Create Organization'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default CreateOrganizationForm;