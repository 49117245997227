import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, db } from '../../api/firebase';
import { Box, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import ViewOrganizationProfile from './view';
import EditOrganizationProfile from './edit';
import { AuthContext } from '../../contexts/AuthContext';

const OrganizationProfileManager = () => {
  const [organization, setOrganization] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        if (!user?.orgId) {
          setError('User is not associated with an organization');
          return;
        }
        const docRef = doc(db, 'organizations', user.orgId);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setOrganization(docSnap.data());
        } else {
          setError('Organization not found');
        }
      } catch (err) {
        setError('Error fetching organization data');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrganization();
  }, [user]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async (updatedData) => {
    try {
      const docRef = doc(db, 'organizations', user.orgId);
      await updateDoc(docRef, updatedData);
      setOrganization(updatedData);
      setIsEditing(false);
      enqueueSnackbar('Organization updated successfully', { variant: 'success' });
    } catch (err) {
      setError('Error updating organization data');
      enqueueSnackbar('Error updating organization: ' + err.message, { variant: 'error' });
      console.error(err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {/* <IconButton onClick={() => navigate(-1)} style={{ marginBottom: '20px' }}>
        <ArrowBack />
        <h4>Back</h4>
      </IconButton> */}
      {isEditing ? (
        <EditOrganizationProfile
          organization={organization}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <ViewOrganizationProfile
          organization={organization}
          onEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default OrganizationProfileManager;