import React, { useState, useEffect } from 'react';
import { TextField, Button, Paper, Grid, Typography, IconButton, CircularProgress, Box,
   FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import { ArrowBack, CloudUpload, Visibility, VisibilityOff } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, db, storage, doc, updateDoc, getDoc, auth, updatePassword } from '../../../api/firebase';
import { useSnackbar } from 'notistack';
import { getAllOrganizations } from '../../../api/firebase-backend';

const EditUserForm = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [profilePicPreview, setProfilePicPreview] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    profilePic: '',
    email: '',
    position: '',
    phoneNo: '',
    orgName: '',
    orgId: '',
    status: 'Inactive',
    password: ''
  });
  const [profilePicFile, setProfilePicFile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData({
            ...userData,
            password: '' // Initialize password as empty
          });
          setProfilePicPreview(userDoc.data().profilePic);
        } else {
          enqueueSnackbar('User not found', { variant: 'error' });
          navigate('/admin/user');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        enqueueSnackbar('Error fetching user data', { variant: 'error' });
      }
    };

    const fetchOrganizations = async () => {
      const orgList = await getAllOrganizations();
      setOrgs(orgList);
    };

    fetchUserData();
    fetchOrganizations();
  }, [userId, navigate, enqueueSnackbar]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleProfilePicChange = (event) => {
    if (event.target.files[0]) {
      setProfilePicFile(event.target.files[0]);
      setProfilePicPreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const uploadProfilePic = async () => {
    if (!profilePicFile) return formData.profilePic;
    const storageRef = ref(storage, `einvoice/users/${profilePicFile.name}`);
    await uploadBytes(storageRef, profilePicFile);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const profilePicUrl = await uploadProfilePic();
      const userData = {
        ...formData,
        orgName: orgs.find(org => org.id === formData.orgId)?.name,
        profilePic: profilePicUrl,
        updatedDate: new Date()
      };
      
       // Remove password from Firestore data
       delete userData.password;

      await updateDoc(doc(db, "users", userId), userData);

      if (formData.password) {
        await updatePassword(auth.currentUser, formData.password);
      }

      enqueueSnackbar('User updated successfully!', { variant: 'success' });
      navigate('/admin/user');
    } catch (error) {
      console.error('Error updating user:', error);
      enqueueSnackbar('Error updating user: ' + error.message, { variant: 'error' });
    }
    setIsLoading(false);
  };

  return (
    <Paper style={{ padding: '20px', maxWidth: '800px', margin: '20px auto' }}>
      <IconButton onClick={() => navigate('/admin/user')} style={{ marginBottom: '20px' }}>
        <ArrowBack />
      </IconButton>
      <Typography variant="h5" gutterBottom>Edit User</Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h6">User Info</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Phone Number"
              name="phoneNo"
              value={formData.phoneNo}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Position"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="profilePic-file"
              type="file"
              onChange={handleProfilePicChange}
            />
            <label htmlFor="profilePic-file">
              <Button
                variant="contained"
                component="span"
                startIcon={<CloudUpload />}
              >
                Change Profile Picture
              </Button>
            </label>
            {profilePicFile && <Typography variant="body2">{profilePicFile.name}</Typography>}
            {profilePicPreview && (
              <Box mt={2} textAlign="center">
                <Typography variant="body2">Preview:</Typography>
                <img src={profilePicPreview} alt="Profile Pic Preview" style={{ maxWidth: '100%', maxHeight: '100px' }} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="org-label">Organization</InputLabel>
              <Select
                labelId="org-label"
                id="orgId"
                name="orgId"
                value={formData.orgId}
                label="Organization"
                onChange={handleChange}
              >
                {orgs.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.id} - {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="status-label">Status</InputLabel>
              <Select
                labelId="status-label"
                id="status"
                name="status"
                value={formData.status}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password (leave blank to keep current)"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={isLoading}>
              {isLoading ? <CircularProgress size={24} /> : 'Update User'}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default EditUserForm;