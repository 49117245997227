import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import {
  Container,
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Grid,
  IconButton,
} from "@mui/material";
import { Search as SearchIcon, X as CancelIcon } from "lucide-react";
import {
  searchDocument,
  cancelDocument,
} from "../../api/api-backend/myinvois_api";
import { formatDate } from "../../utils/helper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const ViewInvoice = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { user } = useContext(AuthContext);
  const [documentId, setDocumentId] = useState("");
  const [document, setDocument] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);

  const handleSearch = React.useCallback(async () => {
    setLoading(true);
    setError("");
    try {
      const result = await searchDocument(user.orgId, documentId);
      if (result) {
        setDocument(result);
      } else {
        // Use dummy data if no result or error
        setError("No document found.");
        setDocument(null);
      }
    } catch (err) {
      setError("An error occurred while searching for the invoice.");
    } finally {
      setLoading(false);
    }
  }, [documentId, user.orgId]);

  useEffect(() => {
    if (initialLoad === true && uuid != null && uuid.length > 0) {
      setDocumentId(uuid);
    } else {
      setInitialLoad(false);
    }
    if (initialLoad === true && documentId.length > 0) {
      handleSearch();
      setInitialLoad(false);
    }
  }, [
    initialLoad,
    setInitialLoad,
    documentId,
    setDocumentId,
    uuid,
    handleSearch,
  ]);

  const handleCancel = async () => {
    setLoading(true);
    setError("");
    try {
      await cancelDocument(user.orgId, document.uuid);
      setDocument(null);
      setDocumentId("");
    } catch (err) {
      setError("An error occurred while canceling the invoice");
    } finally {
      setLoading(false);
      setOpenDialog(false);
    }
  };

  const getFailedValidations = (doc) => {
    if (doc.status === "Invalid") {
      return doc.validationResults.validationSteps
        .filter((step) => step.status === "Invalid")
        .map((step) => step.name + " Failed");
    }
  };

  const renderDocumentDetails = (doc) => (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Invoice Details
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography>
            <strong>UUID:</strong> {doc.uuid}{" "}
            <IconButton
              color="inherit"
              size="small"
              height=""
              onClick={() => navigator.clipboard.writeText(doc.uuid)}
            >
              <FileCopyIcon />
            </IconButton>
          </Typography>
          <Typography>
            <strong>Submission UID:</strong> {doc.submissionUid}
          </Typography>
          <Typography>
            <strong>Long ID:</strong> {doc.longId}
          </Typography>
          <Typography>
            <strong>Internal ID:</strong> {doc.internalId}
          </Typography>
          <Typography>
            <strong>Type Name:</strong> {doc.typeName}
          </Typography>
          <Typography>
            <strong>Type Version:</strong> {doc.typeVersionName}
          </Typography>
          <Typography>
            <strong>Issuer TIN:</strong> {doc.issuerTin}
          </Typography>
          <Typography>
            <strong>Issuer Name:</strong> {doc.issuerName}
          </Typography>
          <Typography>
            <strong>Receiver ID:</strong> {doc.receiverId}
          </Typography>
          <Typography>
            <strong>Receiver Name:</strong> {doc.receiverName}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            <strong>Issued:</strong> {formatDate(doc.dateTimeIssued)}
          </Typography>
          <Typography>
            <strong>Received:</strong> {formatDate(doc.dateTimeReceived)}
          </Typography>
          <Typography>
            <strong>Validated:</strong> {formatDate(doc.dateTimeValidated)}
          </Typography>
          <Typography>
            <strong>Total Excluding Tax:</strong> $
            {doc.totalExcludingTax?.toFixed(2)}
          </Typography>
          <Typography>
            <strong>Total Discount:</strong> ${doc.totalDiscount?.toFixed(2)}
          </Typography>
          <Typography>
            <strong>Total Net Amount:</strong> ${doc.totalNetAmount?.toFixed(2)}
          </Typography>
          <Typography>
            <strong>Total Payable Amount:</strong> $
            {doc.totalPayableAmount?.toFixed(2)}
          </Typography>
          <Typography>
            <strong>Status:</strong> {doc.status}
          </Typography>
          <Typography>
            <strong>Cancel Date:</strong> {formatDate(doc.cancelDateTime)}
          </Typography>
          <Typography>
            <strong>Reject Request Date:</strong>{" "}
            {formatDate(doc.rejectRequestDateTime)}
          </Typography>
          <Typography>
            <strong>Status Reason:</strong> {doc.documentStatusReason || "N/A"}
          </Typography>
          <Typography>
            <strong>Created By:</strong> {doc.createdByUserId}
          </Typography>
        </Grid>
        {doc.status === "Invalid" ? (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Validation Failures
            </Typography>
            <ul>
              {getFailedValidations(doc).map((step, index) => (
                <li key={index}>{step}</li>
              ))}
            </ul>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );

  return (
    <Container maxWidth="md">
      {uuid != null && uuid.length > 0 ? (
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mb: 2 }}
        >
          Back
        </Button>
      ) : null}
      <Typography variant="h4" gutterBottom>
        View Invoice Document
      </Typography>

      <Box display="flex" alignItems="center" mb={3}>
        <TextField
          fullWidth
          variant="outlined"
          label="Invoice ID"
          value={documentId}
          onChange={(e) => setDocumentId(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SearchIcon />}
          onClick={handleSearch}
          disabled={loading}
          sx={{ ml: 2, height: 56 }}
        >
          Search
        </Button>
      </Box>

      {loading && (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
          {error}
        </Alert>
      )}

      {document && (
        <>
          {renderDocumentDetails(document)}
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/creditnote/new/${document.uuid}`)}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                Generate Credit Note
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/debitnote/new/${document.uuid}`)}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                Generate Debit Note
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/refundnote/new/${document.uuid}`)}
                disabled={loading}
                sx={{ mt: 2 }}
              >
                Generate Refund Note
              </Button>
            </Grid>
            {document.status !== "Cancelled" ? (
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<CancelIcon />}
                  onClick={() => setOpenDialog(true)}
                  sx={{ mt: 2 }}
                >
                  Cancel Invoice
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </>
      )}

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Confirm Cancellation</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel this invoice? This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={handleCancel} color="secondary" autoFocus>
            Yes, Cancel Invoice
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default ViewInvoice;
