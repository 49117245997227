import React, { useState } from 'react';
import { auth, signInWithEmailAndPassword } from '../../api/firebase';
import './index.css'; // You'll need to create this CSS file
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  //const [keepSignedIn, setKeepSignedIn] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle login logic here
    try {
      await signInWithEmailAndPassword(auth, email, password);
      //await captureAuditTrail('Login', 'Login', 'User logged in');
      // Redirect to dashboard
      navigate('/dashboard');
    } catch (error) {
      console.error("Error signing in", error);
      setError(error); // Set error state
    }
  };

  return (
    <div className="login-container">
      <div className="login-image">
        {/* You'll need to add the illustration image here */}
      </div>
      <div className="login-form">
        <img src="/elogo.png" alt="Company Logo" style={{ height: '500px', marginRight: '10px' }} />
        <h1 className="logo">Edna: Malaysia Premier E-Invoicing Platform</h1>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="user@mail.com"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {/* <div className="form-footer">
            <label>
              <input
                type="checkbox"
                checked={keepSignedIn}
                onChange={(e) => setKeepSignedIn(e.target.checked)}
              />
              Keep me sign in
            </label>
            <a href="/forgot-password">Forgot Password?</a>
          </div> */}
          <button type="submit" className="login-button">Login</button>
          <div className="login-error">
            {error && <p className='error-message'>Login error. 
              Please ensure email and password are correct.
               If problem persists please contact e-invoicing support</p>}
        </div>
        </form>
        {/* <p className="signup-link">
          Don't have an account? <a href="/signup">Sign up</a>
        </p> */}
      </div>
    </div>
  );
};

export default Login;