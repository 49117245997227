import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse, ListItem, ListItemButton, ListItemIcon, ListItemText, List } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const NestedMenuItem = ({ item, level = 0 }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  // Early exit if not visible
  if ('isVisible' in item && !item.isVisible) {
    return null;
  }

  const indentation = level * 2; // Adjust this multiplier as needed
  const paddingVertical = level > 0 ? 0.3 : 1; // Adjust padding for nested items

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          onClick={handleClick}
          component={item.path ? Link : "button"}
          to={item.path || "#"}
          sx={{ pl: indentation, py: paddingVertical }}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
          {item.children && (open ? <ExpandLess /> : <ExpandMore />)}
        </ListItemButton>
      </ListItem>
      {item.children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.children.map((child) => (
              <NestedMenuItem key={child.text} item={child} level={level + 1} />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default NestedMenuItem;
