// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { getFirestore, doc, getDoc, collection, addDoc, getDocs, query, startAfter, limit,
  orderBy, updateDoc, deleteDoc, where
 } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword, updatePassword } from "firebase/auth";
import { environment } from "../constants/env";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCTh5R9IGTT68GBAUxMCK8VBnEKU7EMChE",
  authDomain: "production-elona.firebaseapp.com",
  projectId: "production-elona",
  storageBucket: "production-elona.appspot.com",
  messagingSenderId: "454162869211",
  appId: "1:454162869211:web:51771fe2443ba4ea8b95fe",
  measurementId: "G-PGYGFJE2M6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const db = getFirestore(app, environment === 'production' ? "einvoice" : "deveinvoice");
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage, ref, uploadBytesResumable, getDownloadURL, uploadBytes,
   signInWithEmailAndPassword, doc, getDoc, signOut, collection, addDoc, getDocs, query, where,
   orderBy, startAfter, limit, updateDoc, deleteDoc, createUserWithEmailAndPassword, updatePassword };