import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import { auth, db, doc, getDoc } from './api/firebase';
import captureAuditTrail from './utils/logger';
import { AuthContext } from './contexts/AuthContext';

// Import your pages

const App = () => {
  const { isAuthenticated, loadingAuthState, role, user } = useContext(AuthContext);
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [loadingAuthState, setLoadingAuthState] = useState(true);
  // const [role, setRole] = useState('');
  // const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const unsubscribe = auth.onAuthStateChanged(async (user) => {
  //     if (user) {
  //       setIsAuthenticated(true);
  //       const userDocRef = doc(db, 'users', user.uid);
  //       const userDoc = await getDoc(userDocRef);
  //       if (userDoc.exists()) {
  //         setRole(userDoc.data().role);
  //         setUser(userDoc.data());
  //         // setIsAuthenticated(true);
  //         // navigate('/');
  //       } else {
  //         setRole('');
  //         setIsAuthenticated(false);
  //         navigate('/login');
  //       }
  //     } else {
  //       setIsAuthenticated(false);
  //       setRole('');
  //       navigate('/login');
  //     }
  //     setLoadingAuthState(false);
  //   });

  //   return () => unsubscribe();
  // }, [navigate]);

  if (loadingAuthState) {
    return (
      <div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="*" element={<MainLayout isAuthenticated={isAuthenticated} role={role} user={user} />} />
    </Routes>
  );
};

export default App;
