import React from 'react';
import { Typography, Paper, Grid, Avatar, Button, Box } from '@mui/material';
import { Edit } from '@mui/icons-material';

const ViewProfile = ({ user, onEdit }) => {
  return (
    <Paper elevation={3} sx={{ p: 3, mt: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Avatar
              src={user.profilePic}
              alt={user.name}
              sx={{ width: 100, height: 100, mb: 2 }}
            />
            <Typography variant="h5" gutterBottom>
              {user.name}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Edit />}
              onClick={onEdit}
              sx={{ mt: 2 }}
            >
              Edit Profile
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Profile Details
          </Typography>
          <Typography>
            <strong>Organization:</strong> {user.orgName}
          </Typography>
          <Typography>
            <strong>Name:</strong> {user.name}
          </Typography>
          <Typography>
            <strong>Email:</strong> {user.email}
          </Typography>
          <Typography>
            <strong>Phone:</strong> {user.phoneNo}
          </Typography>
          <Typography>
            <strong>Position:</strong> {user.position}
          </Typography>
          <Typography>
            <strong>Role:</strong> {user.role}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewProfile;