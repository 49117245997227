import { db, auth, storage, ref, uploadBytesResumable, getDownloadURL, uploadBytes,
    signInWithEmailAndPassword, doc, getDoc, signOut, collection, addDoc, getDocs, query,
    orderBy, startAfter, limit, updateDoc, deleteDoc  } from './firebase';

const getAllOrganizations = async () => {
    const organizations = [];
    const querySnapshot = await getDocs(collection(db, 'organizations'));
    querySnapshot.forEach((doc) => {
        organizations.push({ id: doc.id, ...doc.data() });
    });
    return organizations;
};

const getOrganization = async (organizationId) => {
    const docRef = doc(db, 'organizations', organizationId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    }
    return null;
};

const createOrganization = async (organizationData) => {
    const docRef = await addDoc(collection(db, 'organizations'), organizationData);
    return docRef.id;
};

export { getAllOrganizations, getOrganization, createOrganization };
