import React, { useState, useEffect, useContext } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Checkbox,
  TablePagination,
  Typography,
  FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Box,
    Tab
} from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../api/firebase'; // Adjust this import based on your Firebase setup
import { AuthContext } from '../../contexts/AuthContext';
import { formatDate } from '../../utils/helper';
import { getFileUrl } from '../../utils/firebase_helper';
import { useNavigate } from 'react-router-dom';

const InvoiceSubmissionList = () => {
    console.log('InvoiceSubmissionList');
    const navigate = useNavigate();  
  const [submissions, setSubmissions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [organizations, setOrganizations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState('');
    const [loading, setLoading] = useState(true);
    const { user } = useContext(AuthContext);
    const isAdmin = user.role === 'admin';
    const currentUser = user;

    useEffect(() => {
        const fetchOrganizations = async () => {
          if (isAdmin) {
            setLoading(true);
            try {
              const orgsCollection = collection(db, 'organizations');
              const orgsSnapshot = await getDocs(orgsCollection);
              const orgsList = orgsSnapshot.docs.map(doc => ({
                id: doc.id,
                name: doc.data().name
              }));
              setOrganizations(orgsList);
            } catch (error) {
              console.error("Error fetching organizations:", error);
            } finally {
              setLoading(false);
            }
          }
        };
    
        fetchOrganizations();
      }, [isAdmin]);

      useEffect(() => {
        const fetchSubmissions = async () => {
          setLoading(true);
          try {
            let submissionsQuery;;
            if (isAdmin && selectedOrg) {
                submissionsQuery = query(
                    collection(db, 'organizations', selectedOrg, 'submissions'),
                    where('documentType', '==', 'Invoice')
                  );
            } else if (isAdmin) {
              // If admin hasn't selected an org, don't fetch any submissions
              setSubmissions([]);
              setLoading(false);
              return;
            } else {
              // For non-admin users, use their orgId
              submissionsQuery = query(
                collection(db, 'organizations', currentUser.orgId, 'submissions'),
                where('documentType', '==', 'Invoice')
              );
            }
    
            const submissionsSnapshot = await getDocs(submissionsQuery);
            const submissionsList = await Promise.all(
                submissionsSnapshot.docs.map(async (doc) => {
                  const data = doc.data();
                  //const downloadURL = await getFileUrl(data.fileUrl);
                  return {
                    id: doc.id,
                    ...data,
                    //downloadURL
                  };
                })
              );
            setSubmissions(submissionsList);
          } catch (error) {
            console.error("Error fetching submissions:", error);
          } finally {
            setLoading(false);
          }
        };
    
        fetchSubmissions();
      }, [isAdmin, selectedOrg, currentUser]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };

    const getDocumentNames = (documents) => {
        if (!documents) return '';
        return documents.map(doc => doc.invoiceCodeNumber).join(', ');
    };

    const handleRowClick = (submissionUid) => {
      //assign selectedOrg to the submissionUid
      //if selectedOrg is empty, then assign the currentUser.orgId
      if (isAdmin && selectedOrg === '') {
        setSelectedOrg(currentUser.orgId);
        navigate(`/invoices/submissions/${submissionUid}/organization/${selectedOrg}`);
        } else if(selectedOrg !== '') {
          navigate(`/invoices/submissions/${submissionUid}/organization/${selectedOrg}`);
        } else {
          navigate(`/invoices/submissions/${submissionUid}/organization/${currentUser.orgId}`);
        }
      };

      const handleDownloadClick = (event, submissionFileUrl) => {
        console.log('download clicked');
      event.stopPropagation(); 
      // Handle download logic if needed. You might not need anything here 
      // if the <a href> is sufficient for your download.
    };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <Typography variant="h6" gutterBottom component="div" sx={{ padding: 2 }}>
        Invoice Submissions
      </Typography>
      {isAdmin && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="org-select-label">Organization</InputLabel>
          <Select
            labelId="org-select-label"
            id="org-select"
            value={selectedOrg}
            label="Organization"
            onChange={handleOrgChange}
          >
            <MenuItem value="">
              <em>Select an Organization</em>
            </MenuItem>
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    {submissions.length > 0 ? (
        <>
          <TableContainer sx={{ maxHeight: '80vh', overflow: 'auto' }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      inputProps={{
                        'aria-label': 'select all submissions',
                      }}
                    />
                  </TableCell>
                  <TableCell>Submission UID</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Updated Date</TableCell>
                  <TableCell>Total Documents</TableCell>
                  <TableCell>Accepted Documents</TableCell>
                  <TableCell>Rejected Documents</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submissions
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((submission) => (
                    <TableRow 
                      hover 
                      key={submission.submissionUid} 
                      onClick={() => handleRowClick(submission.submissionUid)}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" />
                      </TableCell>
                      <TableCell>{submission.submissionUid}</TableCell>
                        <TableCell>{formatDate(submission.createdDate)}</TableCell>
                        <TableCell>{formatDate(submission.updatedDate)}</TableCell>
                        <TableCell>{submission.totalDocumentCount}</TableCell>
                        <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '300px' }}>
                            {getDocumentNames(submission.acceptedDocuments)}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'normal', wordWrap: 'break-word', maxWidth: '300px' }}>
                            {getDocumentNames(submission.rejectedDocuments)}
                        </TableCell>
                        <TableCell>
                        <a 
                          href={submission.fileUrl} 
                          target="_blank" 
                          rel="noreferrer"
                          onClick={(event) => handleDownloadClick(event, submission.fileUrl)}
                        >
                          Download
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={submissions.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Typography variant="body1" sx={{ padding: 2 }}>
          {isAdmin && !selectedOrg 
            ? "Please select an organization to view submissions." 
            : "No submissions found."}
        </Typography>
      )}
    </Paper>
  );
};

export default InvoiceSubmissionList;