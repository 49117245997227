import axios from 'axios';
import { MYINVOIS_API_BASE_URL } from '../../constants/env';

const API_BASE_URL = MYINVOIS_API_BASE_URL;

export const userApi = {
  getUser: async (userId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/GetUser`, { params: { userId } });
      return response.data;
    } catch (error) {
      console.error('Error fetching user:', error);
      throw error;
    }
  },

  updateUser: async (userId, userData) => {
    try {
      const response = await axios.put(`${API_BASE_URL}/UpdateUser`, { userId, ...userData });
      return response.data;
    } catch (error) {
      console.error('Error updating user:', error);
      throw error;
    }
  },

  deleteUser: async (userId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/user`, {
        data: { userId }
      });
      console.log('User deleted:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error deleting user:', error);
      throw error;
    }
  },

    createUser: async (userData) => {
        try {
        const response = await axios.post(`${API_BASE_URL}/user`, userData);
        //const response = await axios.post(`${API_BASE_URL}/test`, userData);
        console.log('User created:', response.data);
        return response.data;
        } catch (error) {
        console.error('Error creating user:', error);
        throw error;
        }
    },
};