import { createTheme } from '@mui/material/styles';

export const themeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#00a854',
    },
    secondary: {
      main: '#f50057',
    },
    text: {
      primary: 'rgba(0,0,0,0.64)',
    },
  },
};

const theme = createTheme(themeOptions);
export default theme;
