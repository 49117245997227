// src/routes.js
import Dashboard from '../pages/Dashboard'; // Example component
// import ComponentsPage from './pages/ComponentsPage'; // Example component
// import StatisticsPage from './pages/StatisticsPage'; // Example component
// Import other page components...
import DashboardIcon from '@mui/icons-material/Dashboard'; // Example icon
import AssignmentIcon from '@mui/icons-material/Assignment';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CancelIcon from '@mui/icons-material/Cancel';
import GroupIcon from '@mui/icons-material/Group';
import OrganizationIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import EditIcon from '@mui/icons-material/Edit';
import Organization from '../pages/Admin/Organization';
import CreateOrganizationForm from '../pages/Admin/Organization/create';
import OrganizationManager from '../pages/Admin/Organization/manager';
import { People } from '@mui/icons-material';
import User from '../pages/Admin/User';
import CreateUserForm from '../pages/Admin/User/create';
import UserManager from '../pages/Admin/User/manager';
import ProfileManager from '../pages/Profile/manager';
import { isVisible } from '@testing-library/user-event/dist/utils';
import OrganizationProfileManager from '../pages/OrganizationProfile/manager';
import InvoiceSubmissionList from '../pages/Invoice/InvoiceSubmissionList';
import InvoiceSubmissionDetail from '../pages/Invoice/InvoiceSubmissionDetail';
import CreditNoteSubmissionList from '../pages/CreditNote/CreditNoteSubmissionList';
import CreditNoteSubmissionDetail from '../pages/CreditNote/CreditNoteSubmissionDetail';
import DebitNoteSubmissionList from '../pages/DebitNote/DebitNoteSubmissionList';
import DebitNoteSubmissionDetail from '../pages/DebitNote/DebitNoteSubmissionDetail';
import RefundNoteSubmissionDetail from '../pages/RefundNote/RefundNoteSubmissionDetail';
import RefundNoteSubmissionList from '../pages/RefundNote/RefundNoteSubmissionList';
import FileUpload from '../pages/Upload';
import ViewInvoice from '../pages/Invoice/ViewInvoice';
import ViewCreditNote from '../pages/CreditNote/ViewICreditNote';
import ViewDebitNote from '../pages/DebitNote/ViewDebitNote';
import ViewRefundNote from '../pages/RefundNote/ViewRefundNote';
import { NewCreditNotePage, NewDebitNotePage, NewRefundNotePage } from '../pages/Invoice/GenerateNewDocument';

const routes = [
  { path: '/', component: Dashboard, text: 'Dashboard', icon: <DashboardIcon />, isVisible: false },
  { path: '/dashboard', component: Dashboard, text: 'Dashboard', icon: <DashboardIcon /> },
  { path: '/profile', component: ProfileManager, text: 'Dashboard', icon: <DashboardIcon />, isVisible: false},
  { path: '/organization-profile', component: OrganizationProfileManager, text: 'Dashboard', icon: <DashboardIcon />, isVisible: false},
  { text: 'Upload', icon: <UploadFileIcon />, component: FileUpload, path: '/submitdocument' },
  {
    text: 'Invoices',
    icon: <AssignmentIcon />,
    children: [
        { text: 'Submissions', component: InvoiceSubmissionList, icon: <ArrowUpwardIcon />, path: '/invoices/submissions' },
        { text: 'Submission Detail', component: InvoiceSubmissionDetail, icon: <ArrowUpwardIcon />, path: '/invoices/submissions/:submissionUid/organization/:organizationId', isVisible: false },
        { text: 'View', component: ViewInvoice, icon: <EditIcon />, path: '/invoices/view' },
        { text: 'View Document', component: ViewInvoice, icon: <EditIcon />, path: '/invoices/view/document/:uuid', isVisible: false },

    ],
  },
  {
    text: 'Credit Note',
    icon: <AssignmentIcon />,
    children: [
        { text: 'Submissions', icon: <ArrowUpwardIcon />, component: CreditNoteSubmissionList, path: '/creditnote/submissions' },
        { text: 'Submission Detail', component: CreditNoteSubmissionDetail, icon: <ArrowUpwardIcon />, path: '/creditnote/submissions/:submissionUid/organization/:organizationId', isVisible: false },
        { text: 'View', component: ViewCreditNote, icon: <EditIcon />, path: '/creditnote/view' },
        { text: 'View Document', component: ViewCreditNote, icon: <EditIcon />, path: '/creditnote/view/document/:uuid', isVisible: false },
        { text: 'Generate Credit Note', component: NewCreditNotePage, icon: <EditIcon />, path: '/creditnote/new/:uuid', isVisible: false },
    ],
  },
  {
    text: 'Debit Note',
    icon: <AssignmentIcon />,
    children: [
        { text: 'Submissions', component: DebitNoteSubmissionList, icon: <ArrowUpwardIcon />, path: '/debitnote/submissions' },
        { text: 'Submission Detail', component: DebitNoteSubmissionDetail, icon: <ArrowUpwardIcon />, path: '/debitnote/submissions/:submissionUid/organization/:organizationId', isVisible: false },
        { text: 'View', component: ViewDebitNote, icon: <EditIcon />, path: '/debitnote/view' },
        { text: 'View Document', component: ViewDebitNote, icon: <EditIcon />, path: '/debitnote/view/document/:uuid', isVisible: false },
        { text: 'Generate Debit Note', component: NewDebitNotePage, icon: <EditIcon />, path: '/debitnote/new/:uuid', isVisible: false },
    ],
  },
  {
    text: 'Refund Note',
    icon: <AssignmentIcon />,
    children: [
        { text: 'Submissions', component: RefundNoteSubmissionList, icon: <ArrowUpwardIcon />, path: '/refundnote/submissions' },
        { text: 'Submission Detail', component: RefundNoteSubmissionDetail, icon: <ArrowUpwardIcon />, path: '/refundnote/submissions/:submissionUid/organization/:organizationId', isVisible: false },
        { text: 'View', component: ViewRefundNote, icon: <EditIcon />, path: '/refundnote/view' },
        { text: 'View Document', component: ViewRefundNote, icon: <EditIcon />, path: '/refundnote/view/document/:uuid', isVisible: false },
        { text: 'Generate Refund Note', component: NewRefundNotePage, icon: <EditIcon />, path: '/refundnote/new/:uuid', isVisible: false },
    ],
  },
];

const adminRoutes = [
  { path: '/admin/organization', component: Organization, text: 'Organization', icon: <OrganizationIcon /> },
  { path: '/admin/organization/create', component: CreateOrganizationForm, text: 'Organization', icon: <OrganizationIcon />, isVisible: false},
  { path: '/admin/organization/manager/:organizationId', component: OrganizationManager, text: 'Organization', icon: <OrganizationIcon />, isVisible: false},
  { path: '/admin/user', component: User, text: 'User', icon: <GroupIcon />},
  { path: '/admin/user/create', component: CreateUserForm, text: 'User', icon: <GroupIcon />, isVisible: false},
  { path: '/admin/user/manager/:userId', component: UserManager, text: 'User', icon: <GroupIcon />, isVisible: false}
];

export { routes, adminRoutes };
