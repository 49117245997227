import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, db } from '../../api/firebase';
import { Box, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import EditProfileForm from './edit';
import ViewProfile from './view';
import { AuthContext } from '../../contexts/AuthContext';

const ProfileManager = () => {
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user: authUser } = useContext(AuthContext);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!authUser?.uid) {
          setError('User not authenticated');
          return;
        }
        const docRef = doc(db, 'users', authUser.uid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          setUser(docSnap.data());
        } else {
          setError('User profile not found');
        }
      } catch (err) {
        setError('Error fetching user profile');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [authUser]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = async (updatedData) => {
    try {
      const docRef = doc(db, 'users', authUser.uid);
      await updateDoc(docRef, updatedData);
      setUser(updatedData);
      setIsEditing(false);
      enqueueSnackbar('Profile updated successfully', { variant: 'success' });
    } catch (err) {
      setError('Error updating profile');
      enqueueSnackbar('Error updating profile: ' + err.message, { variant: 'error' });
      console.error(err);
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {/* <IconButton onClick={() => navigate(-1)} style={{ marginBottom: '20px' }}>
        <ArrowBack />
        <h4>Back</h4>
      </IconButton> */}
      {isEditing ? (
        <EditProfileForm
          user={user}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      ) : (
        <ViewProfile
          user={user}
          onEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default ProfileManager;