import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
} from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';

const DeleteDialog = ({ open, onClose, onConfirm, itemName }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        <IconButton
          aria-label="delete"
          sx={{
            color: 'error.main',
            backgroundColor: 'error.light',
            '&:hover': { backgroundColor: 'error.light' },
            mr: 2,
          }}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
        Are you sure you want to delete?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          By deleting "{itemName}", its details will also be removed from the database.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
        <Button onClick={onClose} variant="outlined" sx={{ width: 120 }}>
          Cancel
        </Button>
        <Button onClick={onConfirm} variant="contained" color="error" sx={{ width: 120 }}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;