import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer,
     TableHead, TableRow, Paper, TextField, Select, MenuItem,
      IconButton, Avatar, Chip, Pagination, Button } from '@mui/material';
import { Visibility, Edit, Delete, Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL, db, storage, collection, addDoc, getDocs,
    query, orderBy, startAfter, limit } from '../../../api/firebase';

const User = () => {
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState('');
  const [filter, setFilter] = useState('All');
  const navigate = useNavigate();

  useEffect(() => {
    loadUsers();
  }, [page, search, sort, filter]);

  const loadUsers = async () => {
    let q;
    if (lastVisible && page > 1) {
      q = query(collection(db, "users"), orderBy("name"), startAfter(lastVisible), limit(5));
    } else {
      q = query(collection(db, "users"), orderBy("name"), limit(5));
    }

    const querySnapshot = await getDocs(q);
    const userList = [];
    querySnapshot.forEach((doc) => {
      userList.push({ id: doc.id, ...doc.data() });
    });
    setUsers(userList);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    //setPage(1);
  };

  const handleSortChange = (event) => {
    setSort(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCreateClick = () => {
    navigate('/admin/user/create');
  };
  
  const handleManage = (orgId) => {
    console.log('Manage Users:', orgId);
    navigate('/admin/user/manager/' + orgId);
};

  const getStatusColor = (status) => {
    switch (status) {
      case 'Active': return 'success';
      case 'Inactive': return 'warning';
      //case 'Cancelled': return 'error';
      default: return 'default';
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
        <TextField
          placeholder="Search 5 records..."
          variant="outlined"
          size="small"
          value={search}
          onChange={handleSearchChange}
        />
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleCreateClick}
        >
          Create
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox"></TableCell>
              <TableCell>USER ID</TableCell>
              <TableCell>PROFILE PIC</TableCell>
              <TableCell>EMAIL</TableCell>
              <TableCell>NAME</TableCell>
              <TableCell>PHONE NO</TableCell>
              <TableCell>ORGANIZATION</TableCell>
              <TableCell>STATUS</TableCell>
              <TableCell>ACTIONS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow hover key={user.id}>
                <TableCell padding="checkbox">
                  <input type="checkbox" />
                </TableCell>
                <TableCell>{user.id}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar sx={{ mr: 2, width: 72, height: 72 }} src={user.profilePic}>
                       {!user.profilePic && user.name ? user.name.charAt(0) : ''}
                    </Avatar>
                  </div>
                </TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.phoneNo}</TableCell>
                <TableCell>{user.orgName}</TableCell>
                <TableCell>
                  <Chip label={user.status} color={getStatusColor(user.status)} />
                </TableCell>
                <TableCell>
                  <IconButton size="small" onClick={() => handleManage(user.id)}><Visibility /></IconButton>
                  {/* <IconButton size="small"><Edit /></IconButton> */}
                  {/* <IconButton size="small"><Delete /></IconButton> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
        <div>
          Rows per page: 
          <Select value={20} size="small">
            <MenuItem value={20}>20</MenuItem>
          </Select>
        </div>
        <Pagination
          count={Math.ceil(totalCount / 20)}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
    </Paper>
  );
};

export default User;