import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { 
  Box, 
  Typography, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  LinearProgress, 
  Paper, 
  Container,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Upload as UploadIcon } from 'lucide-react';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { uploadFile } from '../../api/api-backend/myinvois_api';
import { db, collection, getDocs, query, where } from '../../api/firebase';

const FileUpload = () => {
  const { user } = useContext(AuthContext);
  const isAdmin = user.role === 'admin';
  const currentUser = user;
  const [organization, setOrganization] = useState('');
  const [organizations, setOrganizations] = useState([]);
  const [documentType, setDocumentType] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [acceptedDocuments, setAcceptedDocuments] = useState([]);
  const [rejectedDocuments, setRejectedDocuments] = useState([]);

  useEffect(() => {
    const fetchOrganizations = async () => {
      if (isAdmin) {
        setLoading(true);
        try {
          const orgsCollection = collection(db, 'organizations');
          const orgsSnapshot = await getDocs(orgsCollection);
          const orgsList = orgsSnapshot.docs.map(doc => ({
            id: doc.id,
            name: doc.data().name
          }));
          setOrganizations(orgsList);
        } catch (error) {
          console.error("Error fetching organizations:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchOrganizations();
  }, [isAdmin]);

  const handleFileUpload = async (file) => {
    setError(null);
    setUploadStatus('Uploading...');
    
    //check orgId. If admin use selectedOrg, else use currentUser.organization
    const organizationId = isAdmin ? selectedOrg : currentUser.orgId;

    try {
      const result = await uploadFile(
        file, 
        documentType, 
        organizationId,
        (progress) => setUploadProgress(progress)
      );

      setAcceptedDocuments(result.acceptedDocuments || []);
      setRejectedDocuments(result.rejectedDocuments || []);
      setUploadStatus(`${result.submissionUid !== null ? `File uploaded successfuly. Submission UID: ${result.submissionUid}` : 'There is an error in the file. Please check the rejected documents.'}`);

    } catch (err) {
      setError(`Upload failed: ${err.message}`);
      setUploadStatus('Upload failed. Please try again.');
    } finally {
      setUploadProgress(0);
    }
  };

  const handleOrgChange = (event) => {
    setSelectedOrg(event.target.value);
  };
  
    const onDrop = useCallback((acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleFileUpload(acceptedFiles[0]);
      }
    }, [documentType, organization, isAdmin]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Upload File
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Upload your document here using this template: <a href="https://develonastorage.blob.core.windows.net/uploads/default_template.xlsx" target="_blank" rel="noreferrer">Download Template</a>
      </Typography>

      {isAdmin && (
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="org-select-label">Organization</InputLabel>
          <Select
            labelId="org-select-label"
            id="org-select"
            value={selectedOrg}
            label="Organization"
            onChange={handleOrgChange}
          >
            <MenuItem value="">
              <em>Select an Organization</em>
            </MenuItem>
            {organizations.map((org) => (
              <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth margin="normal">
        <InputLabel>Document Type</InputLabel>
        <Select
          value={documentType}
          onChange={(e) => setDocumentType(e.target.value)}
          label="Document Type"
        >
          <MenuItem value="Invoice">Invoice</MenuItem>
          <MenuItem value="CreditNote">Credit Note</MenuItem>
          <MenuItem value="DebitNote">Debit Note</MenuItem>
          <MenuItem value="RefundNote">Refund Note</MenuItem>
        </Select>
      </FormControl>

      <Paper
        {...getRootProps()}
        sx={{
          mt: 3,
          p: 8,
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: isDragActive ? 'action.hover' : 'background.paper',
          border: '2px dashed',
          borderColor: isDragActive ? 'primary.main' : 'divider',
        }}
      >
        <input {...getInputProps()} />
        <UploadIcon size={48} />
        <Typography variant="h6" mt={2}>
          {isDragActive
            ? "Drop the files here"
            : "Drag 'n' drop some files here, or click to select files"}
        </Typography>
      </Paper>

      {uploadProgress > 0 && (
        <Box mt={3}>
          <Typography variant="subtitle1" gutterBottom>
            Upload Progress
          </Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
        </Box>
      )}

      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Upload Status
        </Typography>
        <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
          <Typography>{uploadStatus || 'No files uploaded yet.'}</Typography>
        </Paper>
      </Box>

      {acceptedDocuments.length > 0 && (
        <Box mt={3}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="accepted-docs-content"
              id="accepted-docs-header"
            >
              <Typography>Accepted Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Code</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {acceptedDocuments.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell>{doc.uuid}</TableCell>
                        <TableCell>{doc.invoiceCodeNumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {rejectedDocuments.length > 0 && (
        <Box mt={3}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="rejected-docs-content"
              id="rejected-docs-header"
            >
              <Typography>Rejected Documents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Document Name</TableCell>
                      <TableCell>Reason</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rejectedDocuments.map((doc, index) => (
                      <TableRow key={index}>
                        <TableCell>{doc.invoiceCodeNumber}</TableCell>
                        {
                          // {
                          //   "status": "error",
                          //   "error": {
                          //       "propertyName": null,
                          //       "propertyPath": null,
                          //       "errorCode": "3",
                          //       "error": "Validation Error",
                          //       "errorMS": "Validation Error",
                          //       "target": "DM092CS00065450",
                          //       "innerError": [
                          //           {
                          //               "propertyName": "sender.Name.LocalName",
                          //               "propertyPath": "sender.Name.LocalName",
                          //               "errorCode": null,
                          //               "error": "The 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2:PaidAmount' element is invalid - The value '' is invalid according to its datatype 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2:PaidAmountType' - The string '' is not a valid Decimal value.",
                          //               "errorMS": "The 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2:PaidAmount' element is invalid - The value '' is invalid according to its datatype 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2:PaidAmountType' - The string '' is not a valid Decimal value.",
                          //               "target": "PaidAmount",
                          //               "innerError": null
                          //           }
                          //       ]
                          //   }
                        // }
                            doc.error != null && doc.error.status === 'error' ? (<TableCell>
                            <p>{doc.error.error.error}</p>
                            { 
                              doc.error.error.innerError != null ? doc.error.error.innerError.map((innerError, index) => (
                                <p key={index}>{innerError.target} is invalid</p>
                              )) : <></>
                            }
                          </TableCell>) : <></>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error}
        </Alert>
      )}
    </Container>
  );
};

export default FileUpload;