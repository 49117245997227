import moment from "moment";

//format firebase timestamp to date dd-mm-yyyy
export const formatDate = (timestamp) => {
    //check whether its null or not and whether it has toDate method
    if (!timestamp || !timestamp.toDate) {
        return "";
    }

    return moment(timestamp.toDate()).format("DD-MM-YYYY HH:mm:ss");
}

export const isJSON = (str) => {
    try {
        JSON.stringify(JSON.parse(str));
        return true;
    } catch (e) {
        return false;
    }
}