import { MYINVOIS_API_BASE_URL } from '../../constants/env';
import { isJSON } from '../../utils/helper';

export const fetchSubmissionDetail = async (orgId, submissionUid) => {
  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/myinvois/${orgId}/submission/${submissionUid}`);
    if (!response.ok) {
      throw new Error('Failed to fetch submission detail');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching submission detail:', error);
    throw error;
  }
};

export const uploadFile = async (file, documentType, organizationId, onProgressUpdate) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('documentType', documentType);

  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/myinvois/${organizationId}/SubmitDocuments`, {
      method: 'POST',
      body: formData,
      headers: {
        // Add any necessary headers, e.g., authorization
        // 'Authorization': `Bearer ${getToken()}`
      },
    });

    if (!response.ok) {
      const errorData = await response.text();
      if(isJSON(errorData)) {
        const errorJson = JSON.parse(errorData);
        throw new Error(errorJson.error);
      } else {
        throw new Error(errorData);
      }
    }

    // Get Content-Length for progress calculation
    const contentLength = +response.headers.get('Content-Length');
    let receivedLength = 0;

    // Create a transform stream for progress reporting
    const progressStream = new TransformStream({
      transform(chunk, controller) {
        receivedLength += chunk.length;
        const progress = (receivedLength / contentLength) * 100;
        onProgressUpdate(progress);
        controller.enqueue(chunk); // Pass data through
      },
    });

    // Create a WritableStream to collect the data
    let responseBody = ''; 
    const writableStream = new WritableStream({
      write(chunk) {
        responseBody += new TextDecoder("utf-8").decode(chunk); // Correct: Decode chunk to string
      }
    });

    // Pipe the response stream through the progress stream and then to the WritableStream
    const pipeThroughPromise = response.body.pipeThrough(progressStream).pipeTo(writableStream);

    // Wait for the piping to complete
    await pipeThroughPromise;

    // Debugging: Log the response body before parsing
    console.log("Response Body:", responseBody);

    // Now, you have the complete response body
    try {
      const result = JSON.parse(responseBody); 
      return result;
    } catch (error) {
      console.error('Error parsing JSON:', error);
      throw error; 
    }
  } catch (error) {
    console.error('Upload failed:', error);
    throw error;
  }
};

export const generateDocument = async (orgId, invoiceId, documentType, data) => {
  const url = `${MYINVOIS_API_BASE_URL}/myinvois/${orgId}/document/${invoiceId}/new/${documentType}`;
  console.log('Generating document:', url);
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      var failedResult = await response.json();
      console.log('Error response:', failedResult);
      throw new Error(`Error generating document: ${failedResult.Error.Error}`);
      //throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Document generation failed:', error);
    throw error;
  }
};

export const searchDocument = async (orgId, invoiceId) => {
  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/myinvois/${orgId}/document-details/${invoiceId}`);
    if (!response.ok) {
      if (response.status === 404) {
        return null; // Invoice not found
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    var result = await response.json();
    console.log('result', result);
    return result;
  } catch (error) {
    console.error('Search failed:', error);
    throw error;
  }
};

export const getInvoiceObject = async (orgId, invoiceId) => {
  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/myinvois/${orgId}/document-invoice/${invoiceId}`);
    if (!response.ok) {
      if (response.status === 404) {
        return null; // Invoice not found
      }
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    var result = await response.json();
    console.log('result', result);
    return result;
  } catch (error) {
    console.error('Get invoice object failed:', error);
    throw error;
  }
};

export const cancelDocument = async (orgId, invoiceId, reason) => {
  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/myinvois/${orgId}/document/${invoiceId}/state`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ status: 'cancelled', reason: reason || 'Cancelled by user' })
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Cancellation failed:', error);
    throw error;
  }
};

export const fetchDashboardData = async (month, year, orgId) => {
  try {
    const url = `${MYINVOIS_API_BASE_URL}/dashboard/${orgId}?month=${month}&year=${year}`;
    console.log('Fetching dashboard data:', url);
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching dashboard data:', error);
    throw error;
  }
};

export const fetchDashboardSubmissions = async () => {
  try {
    const response = await fetch(`${MYINVOIS_API_BASE_URL}/recent-submissions`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching recent submissions:', error);
    throw error;
  }
};