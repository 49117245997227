import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  InputBase,
  Avatar,
  Badge,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ViewKanbanIcon from '@mui/icons-material/ViewKanban';
import PeopleIcon from '@mui/icons-material/People';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExtensionIcon from '@mui/icons-material/Extension';
import TableChartIcon from '@mui/icons-material/TableChart';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';

import { Routes, Route, Link } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import { routes, adminRoutes} from '../constants/routes';
import NestedMenuItem from './NestedMenuItem';
import UserProfileMenu from './UserProfileMenu';
import { AuthContext } from '../contexts/AuthContext';
import { environment } from '../constants/env';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const MainLayout = () => {
  const [open, setOpen] = useState(true);
  const { isAuthenticated, role, user } = useContext(AuthContext);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const combinedRoutes = [
    ...routes,
    ...(role === 'admin' ? adminRoutes : [])
  ];

  const renderRoutes = (routesArray) => {
    return routesArray.map((route) => {
      if (route.children) {
        return renderRoutes(route.children);
      }
      return <Route key={route.path} path={route.path} element={<route.component />} />;
    });
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon /> },
    { text: 'Components', icon: <WidgetsIcon /> },
    { text: 'Statistics', icon: <WidgetsIcon /> },
    { text: 'Data', icon: <WidgetsIcon /> },
    { text: 'Chart', icon: <WidgetsIcon /> },
    { text: 'Chat', icon: <ChatIcon /> },
    { text: 'Calendar', icon: <CalendarTodayIcon /> },
    { text: 'Kanban', icon: <ViewKanbanIcon /> },
    { text: 'Customer', icon: <PeopleIcon /> },
    { text: 'Invoice', icon: <ReceiptIcon /> },
    { text: 'Profile', icon: <PersonIcon /> },
    { text: 'E-commerce', icon: <ShoppingCartIcon /> },
    { text: 'Forms Validation', icon: <AssignmentIcon /> },
    { text: 'Forms Wizard', icon: <AssignmentIcon /> },
    { text: 'Layout', icon: <WidgetsIcon /> },
    { text: 'Plugins', icon: <ExtensionIcon /> },
    { text: 'React Table', icon: <TableChartIcon /> },
    { text: 'MUI Table', icon: <TableChartIcon /> },
  ];

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBarStyled position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <img src="/elogo.png" alt="Company Logo" style={{ height: '40px', marginRight: '10px' }} />
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Edna e-invoicing { environment === 'production' ? '' : '[D]' }
          </Typography>
          {/* <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Ctrl + K"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> */}
          {/* <IconButton color="inherit" sx={ {marginRight: '20px'}}>
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {/* <IconButton color="inherit">
            <SettingsIcon />
          </IconButton> */}
          {/* <Avatar sx={{ ml: 2 }} alt="User Avatar" src="/path-to-avatar-image.jpg" /> */}
          <UserProfileMenu />
        </Toolbar>
      </AppBarStyled>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <Typography variant="h6" color="primary">
            Able Pro
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
          {/* {menuItems.map((item, index) => (
            <ListItem key={item.text} disablePadding component={Link} to="/dashboard">
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))} */}
          {/* {routes.map((item) => (
            <ListItem key={item.text} disablePadding component={Link} to={item.path}>
              <ListItemButton>
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} sx={{ color: 'rgb(91, 107, 121)'}} />
              </ListItemButton>
            </ListItem>
          ))} */}
          {combinedRoutes.map((route) => (
            <NestedMenuItem key={route.path} item={route} />
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {/* {children} */}
        <Routes>
          {/* {combinedRoutes.map((route) => (
            <Route key={route.path} path={route.path} element={<route.component />} />
          ))} */}
          {renderRoutes(combinedRoutes)}
        </Routes>
      </Main>
    </Box>
  );
};

export default MainLayout;