import React from "react";
import MainContext from "./MainContext";

const ContextProvider = ({ children }) => {

    return (
        <MainContext.Provider value={{}}>
            {children}
        </MainContext.Provider>
    );
    }

export default ContextProvider;